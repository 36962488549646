import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { Row, Col } from 'react-simple-flex-grid'
import { graphql } from 'gatsby'
import { v4 as uuidv4 } from 'uuid'

import { BlueText, Container, Heading, Image, SliderNav, Text } from '_atoms'
import { CaseStudies, Hero, ImageSlider, TinySlider, CompanyStats } from '_molecules'
import { SEO, StripedQuote } from '_organisms'
import { Layout } from '_templates'
import ArrowRight from '_images/svgs/arrow-right.inline.svg'

import styles from './styles.module.css'

const cx = classNames.bind(styles)

const TESTIMONIALS_SLIDER_OPTIONS = {
  controls: false,
  mouseDrag: true,
  loop: true,
  center: true,
  autoplay: true,
  autoplayHoverPause: true,
  autoplayTimeout: 8000,
  autoplayText: ['', ''],
  navContainer: '#quote-nav',
}

const AWARDS_SLIDER_OPTIONS = {
  controls: false,
  mouseDrag: true,
  loop: false,
  items: 1.3,
  gap: 36,
  navContainer: '#awards-nav',
}

const filterAwards = data => {
  const years = new Set(data.map(({ year }) => year))
  const awards = [...years].map(currentYear => {
    // filter by year and sort by desc awardtype name
    const currentYearAwards = data
      .filter(({ year }) => year === currentYear)
      .sort(({ awardType }, { awardType: currentAwardType }) =>
        awardType.name < currentAwardType.name ? 1 : -1
      )

    return { year: currentYear, awards: currentYearAwards }
  })

  return awards
}

const AboutPage = ({ data }) => {
  const { aboutTitle, aboutDescription, aboutThumbnail } = data.contentfulPageMetatags
  const sliderImages = data.allContentfulAboutSliderPictures.nodes
  const allTestimonials = data.allContentfulQuote.nodes
  const allAwards = filterAwards(data.allContentfulAwards.nodes)
  const allLeadership = data.allContentfulLeadership.nodes

  return (
    <Layout>
      <SEO
        title={aboutTitle || 'Web &amp; Mobile App Design and Development Company'}
        description={aboutDescription || 'Web &amp; Mobile App Design and Development Company'}
        thumbnail={aboutThumbnail.file.url}
      />

      <Hero
        breakline
        className={styles.title}
        title="about.hero.title"
        subTitle="about.hero.subtitle"
      />

      <section className={styles.beginning}>
        <Container>
          <div className={styles.beginningContent}>
            <Heading type="h2" className={styles.heading}>
              <span className={styles.headingLight}>
                <FormattedMessage id="about.beginning.titleFirstLine" />{' '}
              </span>
              <span className={styles.headingBold}>
                <FormattedMessage id="about.beginning.titleSecondLine" />
                <BlueText squared>.</BlueText>
              </span>
            </Heading>

            <div className={styles.beginningText}>
              <FormattedMessage
                id="about.beginning.content"
                values={{ p: (...chunks) => <Text size="20"> {chunks} </Text> }}
              />
            </div>
          </div>
        </Container>
      </section>
      <section>
        <CompanyStats />
      </section>
      <section>
        <div className={styles.slider}>
          <ImageSlider id="about-slider" className={styles.sliderContent}>
            {sliderImages.map(({ image }) => (
              <Image alt={image.description} key={image.fluid.src} {...image.fluid} />
            ))}
          </ImageSlider>
        </div>
      </section>
      {/*
        <section className={styles.video}>
          <Container className={styles.videoContainer}>
          {/ eslint-disable-next-line jsx-a11y/media-has-caption /}
          <video src="#" className={styles.videoContent} />
        </Container>
      </section>
      */}
      <section className={styles.status}>
        <div className={styles.awards}>
          <Container className={styles.awardsContainer}>
            <Heading type="h2" className={cx('headingH2', 'awardsTitle')}>
              <FormattedMessage id="about.awards.title" />
              <BlueText squared>.</BlueText>
            </Heading>

            <div className={styles.awardsList}>
              {allAwards.map(({ year, awards }) => (
                <div key={year}>
                  <Row>
                    <Col md={6} lg={6} className={styles.awardsEmptyCol} />
                    <Col md={6} lg={6} className={styles.awardsRightCol}>
                      <Text size="48" color="dark" className={styles.awardsHeading}>
                        {year}
                      </Text>
                    </Col>
                  </Row>
                  <div className={styles.awardsGrid}>
                    {awards.map(({ ranking, rankingType, awardType, articleName, articleLink }) => (
                      <div key={ranking} className={cx(articleLink ? 'awardsCard' : 'awardsBlock')}>
                        {articleLink && (
                          <div className={styles.awardsCardWebsite}>
                            <Text size="16" color="white" className={styles.awardsArticle}>
                              {awardType.name}
                            </Text>
                            <Text size="24" color="white">
                              {articleName}
                            </Text>

                            <a
                              href={articleLink}
                              rel="noopener noreferrer"
                              target="_blank"
                              className={styles.awardsArticleLink}
                            >
                              <Text size="16" color="accent">
                                <FormattedMessage id="about.awards.readMore" />
                              </Text>
                              <ArrowRight className={styles.awardsArticleArrow} />
                            </a>
                          </div>
                        )}
                        <div className={styles.awardsCardContent}>
                          <Image
                            alt={awardType.name}
                            {...awardType.logo.fixed}
                            className={styles.awardsLogo}
                          />
                          <Text size="24" color="dark" className={styles.awardsCardRanking}>
                            {ranking}
                          </Text>
                          <Text size="16" className={styles.awardsCardType}>
                            {rankingType}
                          </Text>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.awardsSlider}>
              <TinySlider
                id="awards"
                options={AWARDS_SLIDER_OPTIONS}
                className={styles.awardsSliderContainer}
              >
                {allAwards.map(({ year, awards }) => (
                  <div key={year}>
                    <Text size="32" color="dark" className={styles.awardsHeading}>
                      {year}.
                    </Text>
                    {awards.map(({ ranking, rankingType, awardType }) => (
                      <div key={ranking} className={styles.awardsSliderCard}>
                        <Image alt={awardType.name} {...awardType.logo.fixed} />
                        <div className={styles.awardsSliderCardText}>
                          <Text size="16" color="dark" className={styles.awardsSliderCardRanking}>
                            {ranking}
                          </Text>
                          <Text size="16">{rankingType}</Text>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </TinySlider>
              <div id="awards-nav" className={styles.awardsIcons}>
                {allAwards.map(({ year }) => (
                  <SliderNav key={year} />
                ))}
              </div>
            </div>
          </Container>
        </div>
        <StripedQuote sliderOptions={TESTIMONIALS_SLIDER_OPTIONS} quotes={allTestimonials} hasNav />
      </section>

      <section className={styles.leadership}>
        <Container>
          <Heading type="h2" className={cx('headingH2', 'leadershipTitle')}>
            <FormattedMessage
              id="about.leadership.title"
              values={{ span: (...chunks) => <span>{chunks}</span> }}
            />
            <BlueText squared>.</BlueText>
          </Heading>

          <div className={styles.leadershipList}>
            {allLeadership.map(({ name, position, picture, link }) => (
              <div className={styles.leadershipItem} key={`leadership-${uuidv4()}`}>
                <div className={styles.leadershipCard}>
                  <div className={styles.leadershipPicture}>
                    <Image {...picture.fixed} alt={name} />
                  </div>
                  <div className={styles.leadershipText}>
                    <section className={styles.leadershipMask} />
                    <section>
                      <div className={styles.leadershipLine}>
                        <Text size="24" color="white" bold className={styles.leadershipName}>
                          {name}
                        </Text>
                        {link && (
                          <a href={link} rel="noopener noreferrer" target="_blank">
                            <ArrowRight className={styles.leadershipArrow} />
                          </a>
                        )}
                      </div>
                      <Text size="14" className={styles.leadershipPosition}>
                        {position}
                      </Text>
                    </section>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Container>
      </section>
      <CaseStudies linkId="aboutCaseStudiesLink" />
    </Layout>
  )
}

export const query = graphql`
  query AboutPageContent($locale: String) {
    contentfulPageMetatags(fields: { localeKey: { eq: $locale } }) {
      aboutDescription
      aboutTitle
      aboutThumbnail {
        file {
          url
        }
      }
    }
    allContentfulAboutSliderPictures(filter: { fields: { localeKey: { eq: $locale } } }) {
      nodes {
        image {
          description
          fluid(quality: 100) {
            src
            srcSet
          }
        }
        alternateText
      }
    }
    allContentfulClients(
      filter: { fields: { localeKey: { eq: $locale } } }
      sort: { order: ASC, fields: updatedAt }
    ) {
      nodes {
        name
        logo {
          description
          fixed {
            src
            srcSet
          }
        }
      }
    }
    allContentfulQuote(
      filter: {
        quoteCategory: { elemMatch: { name: { eq: "Testimonials" } } }
        fields: { localeKey: { eq: $locale } }
      }
    ) {
      nodes {
        id
        jobDescription
        user
        text {
          internal {
            content
          }
        }
        userImage {
          fixed(width: 64, height: 64, quality: 80) {
            src
            srcSet
          }
        }
      }
    }
    allContentfulAwards(
      filter: { fields: { localeKey: { eq: $locale } } }
      sort: { order: DESC, fields: year }
    ) {
      nodes {
        ranking
        rankingType
        year
        articleName
        articleLink
        awardType {
          name
          logo {
            fixed(width: 108, height: 108, quality: 80) {
              src
              srcSet
            }
          }
        }
      }
    }
    allContentfulLeadership(
      filter: { fields: { localeKey: { eq: $locale } } }
      sort: { order: ASC, fields: order }
    ) {
      nodes {
        name
        node_locale
        position
        link
        picture {
          fixed(width: 265, height: 369, quality: 80) {
            ...GatsbyContentfulFixed_withWebp_noBase64
          }
        }
      }
    }
  }
`

AboutPage.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
}

export default AboutPage
